import React from 'react';
import {Link} from "react-router-dom";
import ServiceBanner from '../images/services-banner.jpg';
import ServicesBox from '../components/ServicesBox';
import {SData} from '../components/SData';
import MetaTag from './MetaTag';
var servicesmetatag = {
  title: "Services | Ecoline Enterprises",
  des: "Providing PU Painting, Acrylic Painting and TSA Painting services to widespread clientele.",
}

function Services() {
  return (
    <>
      <MetaTag title={servicesmetatag.title} des={servicesmetatag.des} />
       <img src={ServiceBanner} className="img-fluid" alt="service-banner"></img>
      <section className="section-content bg-1">
      <div className="container">
      <h2 className="heading-title title">Our <span className="text-skyblue">Services</span></h2>
      <center><div className="underline"></div></center>
      <div className="icons">
      <div className="icon-one"></div>
     <div className="icon-two"></div>
     <div className="icon-three"></div>
     <div className="icon-four"></div>
     <div className="icon-five bg-icon-1"></div>
     <div className="icon-six bg-icon-2"></div>
     <div className="icon-five1 bg-icon-1"></div>
     <div className="icon-six1 bg-icon-2"></div>
     </div>
     <div className="row">
     
{
  SData.map((val, ind) => {
    return <ServicesBox key={ind} imgsrc={val.imgsrc} title={val.title} des={val.des} link={val.link} />
  })
}

</div>
{/* <div className="col-md-10 offset-md-1">
  <div className="quality-section">
  <h4>Quality is not an act, it is a habit</h4>
  </div>
</div> */}

</div><br /><br /><br />
<div className="container">
  <div className="inner-container">
  <h4 className="title p-relative z-index text-info text-center">To know more</h4>
  <center> <Link to="/Contact" className="p-relative z-index"> <button className="default-btn btn-two">CONTACT AN EXPERT</button></Link></center>
  {/* <div className="car-icon moving-car"></div> */}
</div>
</div>
</section>
    </>
  )
}

export default Services;
