import React from 'react';
import ReactHtmlParser from 'react-html-parser';
// import PuPaint from '../images/pu-painting.jpg';
// import Automotive from '../images/automotive.jpg';
// import GlassPaint from '../images/glass-painting.jpg';
// import AboutBanner from '../images/about-banner.jpg';

function SubServices(props) {


  console.log(props.subtitle7);

  return (
    <>
     
  
     <div className="col-md-6 service-box">
      <div className="img-box">
        <img src={props.imgsrc} className="img-fluid" alt={props.title} />
        {/* <i className={props.icon}></i> */}
        </div>
        <div className="service-box-inner">
        <h4 className="sub-title">{props.title}</h4>
        {/* <ul>
          <li>{props.subtitle1}</li>
          <li>{props.subtitle2}</li>
          <li>{props.subtitle3}</li>
          <li>{props.subtitle4}</li>
          <li>{props.subtitle5}</li> 
          <li>{props.subtitle6}</li>

        </ul> */}
       
        <p className="mb-0 sub-points">{ReactHtmlParser(props.subtitle1)}</p>
        <p className="mb-0 sub-points">{ReactHtmlParser(props.subtitle2)}</p>
        <p className="mb-0 sub-points">{ReactHtmlParser(props.subtitle3)}</p>
        <p className="mb-0 sub-points">{ReactHtmlParser(props.subtitle4)}</p>
        <p className="mb-0 sub-points">{ReactHtmlParser(props.subtitle5)}</p>
        <p className="mb-0 sub-points">{ReactHtmlParser(props.subtitle6)}</p>
        <p className="mb-0 sub-points">{ReactHtmlParser(props.subtitle7)}</p>
        {/* <p>&nbsp;</p> */}
        </div>
      </div>
        </>
  )
}

export default SubServices;


