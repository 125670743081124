import React from 'react';


function Error(params) {
  
  return(
 
    <div className="container">
      <div className="error-page">
        <h1 className="">Page Not Found</h1>
      </div>
    </div>
  
  )
}
export default Error;