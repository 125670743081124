import PuPaint from '../images/pupainting-icon.jpg';
import Automotive from '../images/mirror.jpg';
import GlassPaint from '../images/fan-small.jpg';
import PaintIcon from '../images/paint-icon.png';
import Client1 from '../images/eureka-logo.jpg';
import Client2 from '../images/lg-logo.jpg';
import Client3 from '../images/maruti-logo.jpg';
import Client4 from '../images/new-holand-logo.jpg';
import Client5 from '../images/orient-logo.jpg';
import Client6 from '../images/philips-logo.jpg';
import Client7 from '../images/tata-logo.jpg';
import Client8 from '../images/cs-logo.jpg';
import Client9 from '../images/luminous.jpg';
import Client10 from '../images/midia.jpg';
import Client11 from '../images/schinder.jpg';
import Client12 from '../images/yamaha.jpg';

export const MataTags = [
  {
title: "Customized painting solutions | Ecoline Enterprises",
des: "Ecoline enterprises provides the best-suited painting, application technology, and post-application analysis to conform to quality standards.",
  },
{
  title: "Services | Ecoline Enterprises",
  des: "Providing PU Painting, Acrylic Painting and TSA Painting services to widespread clientele.",
    },
{
  title: "PU Painting Services | Ecoline Enterprises",
  des: "Ecoline Enterprises offers a superior range of PU painting services to our wide spread clientele. We provide polyurethane painting that is chemical- resistant coating lending a smooth finish to a variety of industrial and commercial substrates.",
    },
{
  title: "Acrylic Painting Services | Ecoline Enterprises",
  des: "We offer a superior range of acrylic coating services to our wide spread clientele. Having a superb acrylic painting technology, we are able to provide acrylic painting that lends a smooth finish to a variety of industrial and commercial substrates.",
    },
  {
    title: "TSA Painting Services | Ecoline Enterprises",
    des: "TSA thermo setting acrylic are used for metal surfaces, to give them desired colour and finish. TSA also provides these surfaces protection from corrosion. ",
      },
  {
    title: "About | Ecoline Enterprises",
    des: "Established in 1999, Ecoline Enterprises is a high quality, ISO 9001:2000 accredited, paint services provider based in Industrial hub of Ghaziabad.",
      },
  {
title: "Contact | Ecoline Enterprises",
des: "Ecoline Enterprises is a high quality, ISO 9001:2000 accredited, paint services provider based in Industrial hub of Ghaziabad.",
  },
  {
    title: "Ecoline Enterprises",
    des: "Apply online to join our team at Ecoline Enterprises.",
      },
  
  ]

export const SData = [
  {
    imgsrc: PuPaint,
    title: "PU Painting",
    des: "We offer a superior range of PU painting services to our wide spread clientele.",
    link:'/PuPaintingDetail',
  },
  {
    imgsrc: Automotive,
    title: "Acrylic Painting",
    des: "Providing acrylic painting service that is chemical-resistant coating lending a smooth finish to a variety of industrial and commercial substrates.",
    link:'/AcrylicDetail',
  },
  {
    imgsrc: GlassPaint,
    title: "TSA Painting",
    des: "TSA thermo setting acrylic are used for metal surfaces, to give them desired colour and finish. ",
    link:'/TsaDetail',
  },
];

export const sdata1 =[
  {
    imgsrc: GlassPaint,
    title: "Glass Painting Services",
    des: "We offer glass painting services to customers, as per their requirement.",
  },
  {
    imgsrc: Automotive,
    title: "Light Fixtures Painting",
    des: "Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print,",
  },
  {
    imgsrc: PuPaint,
    title: "Fan Blades Painting",
    des: "Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print,",
  },
];
export const sdata2 =[
  {
    imgsrc: GlassPaint,
    title: "Fancy Light Fixture Painting",
    des: "We offer a range of fancy light fixture painting services that enhances the durability & appearance of the fancy light fixtures.",
  },
  {
    imgsrc: Automotive,
    title: "Metallic & Non Metallic Painting",
    des: "We offer a superior range of metallic coating services and non metallic painting services to our clients.",
  },
  {
    imgsrc: PuPaint,
    title: "TV Cabinet Painting",
    des: "We offer hi-finish TV cabinet painting services on various sized TV cabinets to enhance appearance.",
  },
  {
    imgsrc: PuPaint,
    title: "TSA Painting",
    des: "Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print,",
  },
  {
    imgsrc: PuPaint,
    title: "Wood Components Painting",
    des: "Lorem ipsum, or lipsum as it is sometimes known, is dummy text used in laying out print,",
  },
];

export const clientItem = [ Client1,Client2,Client3,Client4,Client5,Client6,Client7,Client8,Client9,Client10,Client11,Client12]

export const testimonaildetail = [
  {
    date: "10 Aug",
  year: "2016",
  author: "Mr R.K. Agarwal",
  des: "Very satisfied with Ecoline’s quality of work. The fit and finish of delivered products was appreciable.",
  },
  {
    date: "10 Aug",
  year: "2016",
  author: "Mr J Kashyap",
  des: "Being a car spare parts manufacturer had been looking for a painting solution company for long. With Ecoline my all requirements were met with good standards of quality.",
  },
  {
    date: "10 Aug",
  year: "2016",
  author: "Mr B.P. Raheja",
  des: "Thank you Ecoline for delivering my finished consignment on time. I respect them for timely delivery of committed number of finished products.",
  },
 
  ];
  export const ServicesList = ["PU Painting","Automotive Component Painting","Glass Painting Services","Fancy Light Fixture Painting","Metallic & Non Metallic Painting","Plastic Component Painting","TV Cabinet Painting","Light Fixtures Painting","Fan Blades Painting","TSA Painting","Wood Components Painting",];
  export const PupaintingSubcategories = [
    
    
    {
      imgsrc: PaintIcon,
    title: "Wood Components",
    subtitle1: "- Table Tops",
    subtitle2: "- Bed Side Cabinets",
    subtitle3: "- Chairs"
    },
    {
      imgsrc: PaintIcon,
    title: "Glass Painting Components",
    subtitle1: "- Liquor Bottles",
    subtitle2: "- Perfume Bottles",
    subtitle3: "- Customized Services",
    },

    {
      imgsrc: PaintIcon,
    title: "Plastic Components Painting",
    subtitle1:'- Vacuum Cleaner',
    subtitle2: '- Water Purifier',
    subtitle3:'- Fan<br />&nbsp;&nbsp;(Canopies, Bottom Rings, Canopy Rings, Blade &nbsp;&nbsp;Trims)', 
    subtitle4: '- Light Fittings<br />&nbsp;&nbsp;(LED Fixtures, Switches, Switch Boards)', 
   
    },
    {
      imgsrc: PaintIcon,
    title: "Automotive Components Painting",
    subtitle1:'- ORVMs',
    subtitle2: '- Door Handles',
    subtitle3:'- Bumper Grills', 
    subtitle4: '- Indoor Handles', 
    subtitle5: '- Garnishes',
    subtitle6: '- Gear Levers Knobs',
    subtitle7: "- Other Components",
    iconclass: "mb-0 sub-points",
    },
  ];
  export const AcrylicSubcategories = [{
    imgsrc: PaintIcon,
    title: "Water Purifier Components",
  },{
    imgsrc: PaintIcon,
    title: "Geyser Components",
  },
  {
    imgsrc: PaintIcon,
    title: "Vacuum Cleaner Components",
  }
  ];
  export const TsaSubcategories = [
    {
      imgsrc: PaintIcon,
      title: "MS Components",
    },
    {
      imgsrc: PaintIcon,
      title: "Aluminium Components",
    },
    {
      imgsrc: PaintIcon,
      title: "Ceiling Fan Blades",
    },
    {
      imgsrc: PaintIcon,
      title: "Fan Motor Top & Bottom",
    },
    {
      imgsrc: PaintIcon,
      title: "Fan Rods",
    }
  ];
  export const MenuItems = [
    {
      title: 'PU Painting',
      path: '/PuPaintingDetail',
      cName: 'dropdown-item'
    },
    {
      title: 'Acrylic Painting',
      path: '/AcrylicDetail',
      cName: 'dropdown-item'
    },
    {
      title: 'TSA Painting',
      path: '/TsaDetail',
      cName: 'dropdown-item'
    }
  ];