import React from 'react';
import ServicesBanner from '../images/PUservice.jpg';
import { PupaintingSubcategories } from '../components/SData';
import SubServices from '../components/SubServices';
import { motion } from 'framer-motion';
import AboutImg from '../images/pupainting-small-banner.jpg';
import ReactHtmlParser from 'react-html-parser';
import MetaTag from './MetaTag';
var pupaintmetatag = {
  title: "PU Painting Services | Ecoline Enterprises",
  des: "Ecoline Enterprises offers a superior range of PU painting services to our wide spread clientele. We provide polyurethane painting that is chemical- resistant coating lending a smooth finish to a variety of industrial and commercial substrates.",
}
const fadeLeft = {
  hidden: { opacity: 0, x: -100 },
  visible: { opacity: 1, x: 0, transition: { duration: 1.5 }}
};



function PuPaintingDetail(params) {
  return(
    <>
     <MetaTag title={pupaintmetatag.title} des={pupaintmetatag.des} />
<img src={ServicesBanner} className="img-fluid" alt="service-banner"></img>
      <section className="section-content">
      <div className="container">
      <h2 className="heading-title title z-index p-relative">PU Painting</h2>
      <center><div className="underline z-index p-relative"></div></center>
      <div className="row mt-5">
     <div className="col-md-12">
     <div className="service-box">
      <div className="row">
      <div className="col-md-6 ">
      <div className="pattern-layer bg-3"></div>
      <div className="pattern-layer-two bg-4"></div>
        
        <div className="z-index p-relative">
        <p>We offer a superior range of PU painting services to our wide spread clientele. We provide polyurethane painting that is chemical- resistant coating lending a smooth finish to a variety of industrial and commercial substrates.</p>
        <p className="mb-0">Additional features of PU coating services include:</p>
        <ul className="pl-3 mb-0" type="disc">
          <li>Exceptional resistance to acids, alkalies, humidity and salts commonly found in industrial applications.</li>
          <li>Paint coating is as per specific requirement viz, Glossy, Semi Glossy, Silky, Matt.</li>
        </ul>
     <p>We offer excellent levels of automotive component painting services for various automotive components such as ORVM's, bumpers, door handles, back door handles, gear levers knobs etc. </p>
        </div>
      
        </div>
     
     <div className="col-md-6">
     <div className="border-bg full-bg"></div>
     <div className="img-hover">

    <motion.div className="rotate" variants={fadeLeft} initial='hidden' animate='visible'>
    <img src={AboutImg} className="img-fluid img-hover" alt="pupaint-img" />
    </motion.div>
    </div>
    </div>
    <div className="col-md-12">
    <div className="height8"></div>
    <p className="z-index p-relative">These services have compliance with international quality standards. Our painting services have been appreciated for its fine finish, shine and durability. These services are provided as per the specifications of our clients to meet their ever-changing expectations. We offer soft feel finishes for interior door handles, gear lever knobs & dash boards etc. </p>
    <p>Ecoline also offers a qualitative range of plastic component painting services for various plastic components. It is available in many colors, textures and durability. The plastic coating services is applicable for: </p>
    <ul className="pl-4">
      <li> Automotive body parts </li>
      <li>Tool handles </li>
      <li>Fitness equipment </li>
      <li>Appliances </li>
    </ul>
   </div>
    </div>
    </div>
    </div>
    </div>
    </div>
    </section>

      <section className="section-content bg-1">
      <div className="container">
      <h2 className="heading-title title">Applications</h2>
      <center><div className="underline"></div></center>
      <div className="icons1">
      <div className="icon-one-service"></div>
      <div className="icon-two-service"></div>
      <div className="icon-three-service bg-icon-3"></div>
      <div className="icon-four-service bg-icon-4"></div>
      <div className="icon-five-service bg-icon-5"></div>
      <div className="icon-six-service bg-icon-6"></div>
      </div>
     <br /><br />
      {
 PupaintingSubcategories.map((val, ind) => {
    return <SubServices key={ind} imgsrc={val.imgsrc} title={val.title}  subtitle1={val.subtitle1} subtitle2={val.subtitle2} subtitle3={val.subtitle3} subtitle4={val.subtitle4} subtitle5={val.subtitle5} subtitle6={val.subtitle6} subtitle7={val.subtitle7} iconclassName={val.iconclass}>
      </SubServices>
  })
}
      </div>
      </section>
    </>
  )
}
export default PuPaintingDetail;