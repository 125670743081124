import React from 'react';
function TestimonialBox(props) {
  return (
    <>
    <div className="col-md-4">
    <div className="testimonial-details">
          
            {/* <ul>
                <li className="post-date">{props.date} <span> {props.year}</span> </li>
                <li className="post-author"><a href="#"> &nbsp;{props.author}</a> </li>
            </ul> */}
           <center> <i className="fas fa-quote-left"></i></center>
        
      <p className="testy-data">{props.des}</p>
      <p className="text-right"><span className="f-12">{props.author}</span></p>
      </div>
      </div>
</>
  )
  }
  export default TestimonialBox;