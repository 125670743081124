import React from 'react';
import ServicesBanner from '../images/ary-banner.jpg';
import { AcrylicSubcategories } from '../components/SData';
import SubServices from '../components/SubServices';
import { motion } from 'framer-motion';
import AboutImg from '../images/arysmallbanner.jpg';
import MetaTag from './MetaTag';
var acylicmetatag = {
  title: "Acrylic Painting Services | Ecoline Enterprises",
  des: "We offer a superior range of acrylic coating services to our wide spread clientele. Having a superb acrylic painting technology, we are able to provide acrylic painting that lends a smooth finish to a variety of industrial and commercial substrates.",
}
const fadeLeft = {
  hidden: { opacity: 0, x: -100 },
  visible: { opacity: 1, x: 0, transition: { duration: 1.5 }}
};

function AcrylicDetail(params) {
  return(
    <>
     <MetaTag title={acylicmetatag.title} des={acylicmetatag.des} />
<img src={ServicesBanner} className="img-fluid" alt="seervicebanner"></img>
      <section className="section-content">
      <div className="container">
      <h2 className="heading-title title z-index p-relative">Acrylic Painting</h2>
      <center><div className="underline z-index p-relative"></div></center>
     <div className="row mt-5">
     <div className="col-md-12">
     <div className="service-box"> <div className="row">
      <div className="col-md-6">
      <div className="pattern-layer bg-3"></div>
      <div className="pattern-layer-two bg-4"></div>
        <div className="height5 hidden-xs"></div>
        <div className="z-index p-relative">
        <p className="mb-0">We offer a superior range of acrylic coating services to our wide spread clientele. Having a superb acrylic painting technology, we are able to provide acrylic  painting that lends a smooth finish to a variety of industrial and commercial substrates.</p>
        <p>Additional features of acrylic painting services include exceptional resistance to
          some chemicals commonly found in industrial applications. Paint coating is as per specific requirements viz, Glossy, Semi Glossy, Silky, Matt. </p>
          
          <div className="height5 hidden-xs"></div>
        </div>
       </div>
     
       <div className="col-md-5">
       <div className="border-bg full-bg"></div>
      <div className="img-hover">
      <motion.div variants={fadeLeft} initial='hidden' animate='visible'>
      <img src={AboutImg} className="img-fluid img-hover" alt="acrylic-img" />
      </motion.div>
      </div>
      </div>
      </div>
      </div>
      </div>
      </div>
      </div>
      </section>
      {/* <Features /> */}
      {/* <Servicesx /> */}
      
      <section className="section-content bg-1">
      <div className="container">
      <h2 className="heading-title title">Applications</h2>
      <center><div className="underline"></div></center>
      <div className="icons1">
      <div className="icon-one-service"></div>
      <div className="icon-two-service"></div>
      <div className="icon-three-service bg-icon-3"></div>
      <div className="icon-four-service bg-icon-4"></div>
      <div className="icon-five-service bg-icon-5"></div>
      <div className="icon-six-service bg-icon-6"></div>
      </div>
     <br /><br />
      {
  AcrylicSubcategories.map((val, ind) => {
    return <SubServices key={ind} imgsrc={val.imgsrc} title={val.title} />
  })
}
      </div>
      </section>


    </>
  )
}
export default AcrylicDetail;