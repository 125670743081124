import React, {useEffect, useState} from 'react';
// import ContactBanner from '../images/contact1.jpg';
import Logo from '../images/final-logo.jpg';
import CareerBanner from '../images/career.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { Link} from "react-router-dom";
import { motion } from 'framer-motion';
import axios from 'axios';
import Swal from 'sweetalert2'
import MetaTag from './MetaTag';
var careersmetatag = {
  title: "Ecoline Enterprises",
  des: "Apply online to join our team at Ecoline Enterprises.",
}

const fadeRight = {
  hidden: { opacity: 0, x: 100 },
  visible: { opacity: 1, x: 0, transition: { duration: 1.5 }}
};
const fadeLeft = {
  hidden: { opacity: 0, x: -100 },
  visible: { opacity: 1, x: 0, transition: { duration: 1.5 }}
};
// Name : shilpi
// CompanyName : ekant
// Email : shilpi@ekant.com
// Phone : 9898989899
// JobTitle : test
// PostApplied : Dev
// Message : testing
// CopyAndPaste : test
function Careers() {
  const [Name, setName] = useState('');
  const [CompanyName, setCompanyName] = useState('');
  const [Email, setEmail] = useState('');
  const [Phone, setPhone] = useState('');
  const [JobTitle, setJobTitle] = useState('');
  const [PostApplied, setPostApplied] = useState('');
 const [CopyAndPaste, setCopyAndPaste] = useState('');
 const [Message, setMessage] = useState('');
 
    function Callapi2(e) {
      e.preventDefault();

    const mdata = new FormData();
    mdata.append( "Name",Name);
    mdata.append( "CompanyName", CompanyName);
    mdata.append( "Email", Email);
    mdata.append( "Phone", Phone);
    mdata.append( "JobTitle", JobTitle);
    mdata.append("PostApplied", PostApplied);
    mdata.append("CopyAndPaste", CopyAndPaste);
    mdata.append("Message", Message);
 
    axios.post('http://ecoline-enterprises.com/Api/updatecareer.php',mdata)
    .then(function (response) {
    var mdata2 = response.data;
    setName('');
    setCompanyName('');
    setEmail('');
    setPhone('');
    setJobTitle('');
    setPostApplied('');
    setCopyAndPaste('');
    setMessage('');

    console.log(JSON.stringify(mdata2));
    if(mdata2!=null){
      if(mdata2.Response =="1"){
         Swal.fire(
            'Success',
            'From submit successfully',
            'success'
          )
   
      }else{
   
   
         Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text:'Please  fill all required field first',
      
          })
      }
   }else{
      Swal.fire({
         icon: 'error',
         title: 'Oops...',
         text: 'Something went wrong! Retry again',
     
       })
   }
    })
    .catch(function (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong! Retry again',
    
      })
    });
    
    }
  

  return (
    <>
     <MetaTag title={careersmetatag.title} des={careersmetatag.des} />
    <img src={CareerBanner} className="img-fluid" alt="career-banner"></img>
    <section className="section-content bg-2">
    <div className="container">

    <h2 className="heading-title title">Careers</h2>
      <center><div className="underline"></div></center>
      <div className="row mt-5">
      <motion.div className="col-md-7" variants={fadeLeft} initial='hidden' animate='visible'>
      <div className="pattern-layer bg-3"></div>
      <form onSubmit={Callapi2}>
          {/* <form action="updatecareer.php" method="post"> */}
          <div className="row">
            <div className="col-md-6">
          <div className="form-group">
            <label>Name</label>
            <input type="text" className="form-control" name="Name" value={Name} onChange={event => setName(event.target.value)} required />
          </div>
          </div>
          <div className="col-md-6">
          <div className="form-group">
            <label>Company</label>
            <input type="text" className="form-control" name="CompanyName" value={CompanyName} onChange={event => setCompanyName(event.target.value)} />
          </div>
          </div>
          </div>
          <div className="row">
            <div className="col-md-6">
          <div className="form-group">
            <label>Email</label>
            <input type="email" className="form-control" value={Email} className="form-control" name="Email" onChange={event => setEmail(event.target.value)} required />
          </div>
          </div>
          <div className="col-md-6">
          <div className="form-group">
            <label>Phone</label>
            <input type="text" className="form-control" value= {Phone}
            name="Phone" onChange={event => setPhone(event.target.value)} />
          </div>
          </div>
          </div>
          <div className="row">
            {/* <div className="col-md-6">
          <div className="form-group">
            <label>Job Title</label>
            <input type="text" className="form-control" name="JobTitle" value={JobTitle} onChange={event => setJobTitle(event.target.value)} required />
          </div>
          </div> */}
          <div className="col-md-12">
          <div className="form-group">
            <label>Post Applied for</label>
            <input type="text" className="form-control" name="PostApplied" value={PostApplied} onChange={event => setPostApplied(event.target.value)} />
          </div>
          </div>
          </div>
          <div className="col-md-12 pl-0 pr-0 pb-3">
            <label>Message</label>
            <textarea className="form-control" name="Message" value={Message} onChange={event => setMessage(event.target.value)}></textarea>
          </div>
          <div className="col-md-12 pl-0 pr-0">
          <label>Copy and Paste Resume Here</label>
            {/* <label>Upload Your Resume</label> */}
            <textarea className="form-control" name="CopyAndPaste" value={CopyAndPaste} onChange={event => setCopyAndPaste(event.target.value)} ></textarea>
            {/* <input type="file" id="myFile" name="filename" className="form-control" /> */}
          </div>
          <br />
          <center className="p-relative z-index">
            {/* <Link to="/ThankYou">  */}
            <button className="default-btn" name="submit" type="submit">Submit</button>
            {/* </Link> */}
            </center>
          </form>
    </motion.div>
    <motion.div className="col-md-5" variants={fadeRight} initial='hidden' animate='visible'>
    <div className="contact-box-inner mobile-mt-2">
      <img src={Logo} className="img-fluid mobile-width-60" alt="logo" />
      <br /><br />
      <table>
        <tbody>
        <tr>
          <td ><FontAwesomeIcon className="icon-color" icon={faHome} /></td>
          <td><p>&nbsp;&nbsp;&nbsp;</p></td>
          <td><span>C- 18/ 6- 7, B. S. R. Industrial Area,<br></br>Ghaziabad - 201 009, Uttar Pradesh, India </span></td>
        </tr>
        <tr>
          <td><FontAwesomeIcon className="icon-color" icon={faPhone} /></td>
          <td><p>&nbsp;&nbsp;&nbsp;</p></td>
          <td><a href="tel:+91 981 046 7500"><span>+91 981 046 7500</span></a></td>
        </tr>
        <tr>
          <td><FontAwesomeIcon className="icon-color" icon={faEnvelope} /></td>
          <td><p>&nbsp;&nbsp;&nbsp;</p></td>
          <td><a href="mailto:info@ecoline-enterprises.com"><span>info@ecoline-enterprises.com</span></a></td>
        </tr>
        </tbody>
      </table>
    </div>
     </motion.div>
    
    </div>
    
  </div>
  </section>
  
    </>
  )
}

export default Careers;
