import React from 'react';

import Banner from './Banner';
import OurPartners from './OurPartners';
import ServicesSection from './ServiceSection';
import AboutSection from './AboutSection';
import Testimonial from './Testimonial';
import { clientItem } from '../components/SData';
import MetaTag from './MetaTag';
var homemetatag = {
  title: "Customized painting solutions | Ecoline Enterprises",
  des: "Ecoline enterprises provides the best-suited painting, application technology, and post-application analysis to conform to quality standards.",
}
function Home() {
  return (
    <>
    <MetaTag title={homemetatag.title} des={homemetatag.des} />
     <Banner />
    <ServicesSection />
    <AboutSection />
    <OurPartners  itemsArray={clientItem} />
    <Testimonial />
    </>
  )
}

export default Home;
