import React from 'react';
import MetaTags from 'react-meta-tags'; 

function MetaTag(props)
{
return(
    <>
  <MetaTags>
            <title>{props.title}</title>
            <meta property="og:title" content={props.title} />
            <meta name="description" content={props.des} />
            
            {/* <meta property="og:des" content={props.des} /> */}
</MetaTags>
    </>
)
}
export default MetaTag;