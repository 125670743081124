import React from 'react';
import ThankBanner from '../images/thnakyou1.jpg';

function ThankYou() {
  return (
    <>
    <img src={ThankBanner} className="img-fluid" alt="thankyou-banner"></img>
    <section className="section-content bg-1">
    <div className="container">

    <h2 className="heading-title title">Thank You</h2>
      <center><div className="underline"></div></center>
      
      <div className="col-md-12 mt-5">
       <p className="text-center">Thank you for your inquiry.<br />
We will get back to you shortly.</p>
    </div>
    
    
  </div>
  </section>
  
    </>
  )
}

export default ThankYou;
