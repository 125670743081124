import React from 'react';
import {Link} from "react-router-dom";
function Footer() {
  return (
    <div>
     <footer className="site-footer footer-gray-1">
        <div className="footer-top">
            <div className="container">
                <div className="row">
                <div className="col-lg-5 col-md-12 col-sm-12 wow fadeIn">
                       
                            <div className="bx-style-1 m-b15 radius-sm br-col-w1">
                                <div className="col-md-12 pl-0">
                             
                                  <h4 className="text-white mb-0 w-400 text-upper">Ecoline Enterprises</h4>
                                    </div>
                                <div className="contact-detail">
                                    <table>
                                        <tbody>
                                        <tr>
                                    <td><i className="fas fa-home icon-color"></i></td>
                                    <td><p>&nbsp;&nbsp;&nbsp;</p></td>
                                    <td><span className="text-white f-13">C- 18/ 6- 7, B. S. R. Industrial Area,<br></br>Ghaziabad - 201 009, Uttar Pradesh, India </span></td></tr>
                                    {/* <tr><td><FontAwesomeIcon className="icon-color" icon={faPhone} /></td><td><p>&nbsp;&nbsp;&nbsp;</p></td><td><span className="text-white f-13">+91 981 046 7500</span></td></tr>
                                    <tr className="padding-tb"><td><FontAwesomeIcon className="icon-color" icon={faEnvelope} /></td><td><p>&nbsp;&nbsp;&nbsp;</p></td><td><a href="mailto:info@ecoline-enterprises.com"><span className="text-white f-13">&nbsp;info@ecoline-enterprises.com</span></a></td></tr> */}
                                    </tbody>
                                    </table>
                                  
                                </div>
                             
                            </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-12 wow fadeIn mobile-mt-1 hidden-xs" data-wow-delay="0.4s" >
                    <div className="contact-detail">
                                    <table>
                                        <tbody>
                    <tr><td><i className="fas fa-phone icon-color"></i></td><td><p>&nbsp;&nbsp;&nbsp;</p></td><td><a href="tel:+91 981 046 7500"><span className="text-white f-13">+91 981 046 7500</span></a></td></tr>
                                    <tr className="padding-tb"><td><i className="fas fa-envelope icon-color"></i></td><td><p>&nbsp;&nbsp;&nbsp;</p></td><td><a href="mailto:info@ecoline-enterprises.com"><span className="text-white f-13">&nbsp;info@ecoline-enterprises.com</span></a></td></tr>
                                    </tbody>
                                    </table>
                                    </div>
                        {/* <div className="widget">
                       <h6 className="m-b10 text-white font-weight-300 text-uppercase">Connect with us</h6>
                           <form className="chimp">
							<div className="subscribe-alert">
							</div>
							<div className="input-group">
							<input type="email" name="Email" className="form-control" placeholder="Type Your Email Id" required="" />
								<span className="input-group-btn">
								<button className="btn join-btn text-capitalize" type="submit" name="subscription">join</button>
								</span>
							</div>
                        </form>
                        <div className="widget-social-info pull-left text-capitalize pull-none-xs mb-15-xs">
					</div>
                     
                        </div> */}
                    </div>
                    <div className="col-lg-2 col-md-6 col-sm-12 wow fadeIn hidden-xs" data-wow-delay="0.2s" >
                        <div className="widget border-0">
                            <h6 className="m-b20 text-white font-weight-300 text-uppercase">Quick Links</h6>
                            <ul className="list-2">
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="/Services">Services</Link></li>
                                <li><Link to="/About">About us</Link></li>
                                {/* <li><Link to="/Careers">Careers </Link></li>
                                <li><Link to="/Contact">Contact us</Link></li> */}
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-2 col-md-6 col-sm-12 wow fadeIn hidden-xs" data-wow-delay="0.2s" >
                        <div className="widget border-0">
                            <h6 className="m-b20 text-white font-weight-300 text-uppercase">&nbsp;</h6>
                            <ul className="list-2">
                                {/* <li><Link to="/">Home</Link></li>
                                <li><Link to="/Services">Services</Link></li>
                                <li><Link to="/About">About us</Link></li> */}
                                <li><Link to="/Careers">Careers </Link></li>
                                <li><Link to="/Contact">Contact us</Link></li>
                            </ul>
                        </div>
                    </div>
                    
                   
                </div>
            </div>
        </div>
        <div className="footer-bottom">
                <div className="row">
                    <div className="col-md-12 col-sm-12 text-right"> 
                    <a href="http://ekant.com/" target="_black"><span className="f-10">ekant solutions&nbsp;&nbsp;&nbsp;</span></a>
                    </div>
                    {/* <div className="col-md-6 col-sm-6 text-right ">
                        <div className="widget-link ">
                           
                        </div>
                    </div> */}
                </div>
            </div>
        
    </footer>
    </div>
  )
}

export default Footer;
