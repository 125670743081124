import React from 'react';
import ServicesBanner from '../images/services-banner.jpg';
import Features from './Features';
import { ServicesList } from '../components/SData';

function ServiceDetails(props) {
  return (
    <>
       <img src={ServicesBanner} className="img-fluid" alt="service-banner"></img>
      <section className="section-content">
      <div className="container">
      <h2 className="heading-title title">Comprehensive Services</h2>
      <center><div className="underline"></div></center>
     <div className="row mt-5">
     <div className="col-md-9">
     <div className="service-box">
      <div className="service-box-inner">
        <h4 className="service-title">Customized Coating Services</h4>
        <p>We have our expertise in offering effective customized coating solutions to many industries. The painting is provided according to the specific requirements of the clients. Customized painting services for various surface finish includes:</p>
        <p>Glossy, Semi Glossy, Matt, Silky, Soft Feel</p>
        
        <p>Further, we offer painting services on different kinds of substrates:</p>
        <p>Plastics, Glass, Non-ferrous metals, Wood</p>
       </div>
      </div>
      <Features />
      </div>
      <div className="col-md-3">
      <ul className="list-star red list-box">
        {
          ServicesList.map((item,i)=>{
          return<>
           <li>{item}</li>
          </>
          })
        }
      </ul>
     
      {/* <div className="sub-services"><p>Automotive Component Painting</p></div> */}
      </div>

</div>
      </div>
      </section>
    </>
  )
}

export default ServiceDetails;
