import React from 'react';
import { BrowserRouter, Route,Switch} from "react-router-dom";
import './css/custom.css'
import Home from './js/Home';
import Header from './js/Headernew';
import Footer from './js/Footer';
import About from './js/About';
import Services from './js/Services';
import Contact from './js/ContactUs';
import Careers from './js/Career';
import ThankYou from './js/ThankYou';
import ServiceDetails from './js/ServiceDetails';
import ScrollToTop from './components/ScrollToTop';
import PuPaintingDetail from './js/PuPaintingDetail';
import TsaDetail from './js/TsaDetail';
import AcrylicDetail from './js/AcrylicDetail';
import ServiceDetailNew from './js/ServiceDetailsNew';
import Error from './js/Error';
// const refreshPage = ()=>{
//   window.location.reload(false);  }



function App() {

  return (
    <>
     {/* <BrowserRouter basename="/ecoline/node"></BrowserRouter> */}
      <BrowserRouter>
      <ScrollToTop>
        {/* <Navbar /> */}
      <Header />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/About" component={About} />
          <Route path="/Services" component={Services} />
          <Route path="/Contact" component={Contact} />
          <Route path="/Careers" component={Careers} />
          <Route path="/ThankYou" component={ThankYou} />
          <Route path="/ServiceDetails" component={ServiceDetails} />
          <Route path="/PuPaintingDetail" component={PuPaintingDetail} />
          <Route path="/ServiceDetailNew" component={ServiceDetailNew} />
          <Route path="/TsaDetail" component={TsaDetail} />
          <Route path="/AcrylicDetail" component={AcrylicDetail} />
          <Route component={Error} />
        </Switch>
        <Footer/>
        </ScrollToTop>
      </BrowserRouter>

    </>
  )
}

export default App;
