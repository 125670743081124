import React from 'react';
import "react-multi-carousel/lib/styles.css";
import  TestimonialBox from '../components/TestimonialBox';
import {testimonaildetail} from '../components/SData'

// const responsive={
//   desktop: {
//     breakpoint: {
//       max: 3000,
//       min: 1024
//     },
//     items: 3,
//     partialVisibilityGutter: 40
//   },
//   mobile: {
//     breakpoint: {
//       max: 464,
//       min: 0
//     },
//     items: 1,
//     partialVisibilityGutter: 30
//   },
//   tablet: {
//     breakpoint: {
//       max: 1024,
//       min: 464
//     },
//     items: 2,
//     partialVisibilityGutter: 30
//   }
// };

function Testimonial() {
  return (
    <>
      <section className="section-content seller-section bg-6">
      <div className="container">
      <h2 className="heading-title title">Testimonials</h2>
      <center><div className="underline"></div></center>
      <br/>
      <div className="col-md-8 offset-md-2 mb-5 text-center">
      <p className="m-t-1">Read what our customers have to say about us.</p>
      </div>
      {/* <Carousel responsive={responsive} infinite autoPlay> */}
      <div className="row">
   
{
  testimonaildetail.map((val, ind)=>
  {
    return <TestimonialBox key={ind} date={val.date} year={val.year} author={val.author} des={val.des}  />
  })

}
            {/* </Carousel> */}
            </div>
      </div>
      </section>
    </>
  )
}

export default Testimonial;
