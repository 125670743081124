import React from 'react';
import {Link} from "react-router-dom";
import Automotive from '../images/ary-home.jpg';
import Pupainting from '../images/pupainting-homesmall.jpg';
import Glass from '../images/tsa-painting.jpg';
function ServicesSection() {
  return (
    <>
      <section className="section-content deals-section bg-2">
      <div className="container">
      <h2 className="heading-title title">Our <span className="text-skyblue">Services</span></h2>
      <center><div className="underline"></div></center>
      <div className="col-md-8 offset-md-2 mt-5 text-center">
      <p>Providing the best-suited painting, application technology, and post-application analysis to conform to quality standards. We also offer customized painting solutions to meet the varied requirements of automobile, appliances and other industries.
</p>
      </div>
      <div className="row pt-5">
      <div className="col-md-4">
      <Link to="/PuPaintingDetail">
      <div className="img-detail">
          <img src={Pupainting} className="img-fluid img-hover" alt="pu-painting" />
          <div className="img-content">
              <h4 className="img-title">PU Painting</h4>
          </div>	
      </div>
      </Link>
      </div>
      <div className="col-md-4">
      <Link to="/AcrylicDetail">
      <div className="img-detail img-detail-yellow">
      <img src={Automotive} className="img-fluid img-hover" alt="acrylic-painting" />
      <div className="img-content img-content-yellow">
              <h4 className="img-title">Acrylic Painting</h4>
          </div>
      </div>
      </Link>
      </div>
      <div className="col-md-4">
      <Link to="/TsaDetail">
      <div className="img-detail img-detail-red">
      <img src={Glass} className="img-fluid img-hover" alt="tsa-painting" />
      <div className="img-content img-content-red">
              <h4 className="img-title">TSA Painting</h4>
          </div>
      </div>
      </Link>
      </div>
      
      </div>
      {/* <div className="col-md-12 text-center mt-3">
     <Link to="/Services"> <button className="btn read-more-btn">Read More</button></Link>
      </div> */}
      </div>
      </section>
    </>
  )
}

export default ServicesSection;
