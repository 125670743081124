import React, { useState, useEffect }  from 'react';
import Logo from '../images/final-logo.jpg';
import { NavLink, Link } from 'react-router-dom';
import Dropdown from './Dropdown';
import '../css/Navbar.css';
import '../fontawesome-font/css/all.css';
// import { Dropdown, Menu } from 'semantic-ui-react'
// import { DropdownMenu, MenuItem, Dropdown, Toggle, NavDropdown } from 'react-bootstrap-dropdown-menu';
function Header() {
  const [dropdown, setDropdown] = useState(false);
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);
 
  const onMouseEnter = () => {
    if (window.innerWidth <= 960) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth <= 960) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  };
  return (
    <>
     <div className="top-bar">
                <div className="container">
                    <div className="row d-flex justify-content-between">
                        <div className="dlab-topbar-left">
                        </div>
                        <div className="dlab-topbar-right topbar-social">
                            <ul>
                                <li> <i className="fas fa-phone site-button-link"></i>&nbsp;&nbsp;<a href="tel:+91 981 046 7500"><span className="text-white f-13">+91 981 046 7500</span></a></li>
                                <li><a href="mailto:info@ecoline-enterprises.com"> <i className="fas fa-envelope site-button-link"></i>&nbsp;&nbsp;<span className="text-white f-13">&nbsp;info@ecoline-enterprises.com</span></a></li>
                                {/* <li><a href="#" className="site-button-link twitter hover"> <i className="fab fa-linkedin-in"></i></a></li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <nav className='navbar'>
        <div className='navbar-container'>
          <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
          <img className="img-fluid" src={Logo} alt="logo" />
          </Link>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <NavLink to='/' className='nav-links' exact onClick={closeMobileMenu} activeClassName="current">
                Home</NavLink>
            </li>
            {/* <li className="nav-item dropdown">
                    <a href="#" className="nav-link dropdown-toggle" data-toggle="dropdown">Messages</a>
                    <div className="dropdown-menu">
                        <a href="#" className="dropdown-item">Inbox</a>
                        <a href="#" className="dropdown-item">Drafts</a>
                        <a href="#" className="dropdown-item">Sent Items</a>
                        <div className="dropdown-divider"></div>
                        <a href="#"className="dropdown-item">Trash</a>
                    </div>
                </li> */}
            <li className='nav-item' onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}>
            <NavLink to='/Services' className='nav-links dropdown-toggle' activeClassName="current" onClick={closeMobileMenu} >
              {/* <NavLink to="/Services" exact className='nav-links dropdown-toggle' activeClassName="current" data-toggle="dropdown" onClick={closeMobileMenu}
              > */}
                Services</NavLink> 
                {/* <Dropdown /> */}
           
               {dropdown && <Dropdown />}
   {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
        <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
        <NavDropdown.Item href="#action/3.2">Another action</NavDropdown.Item>
        <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
      </NavDropdown> */}
   
               {/* <div className="dropdown-menu">
               <Link to="/PuPaintingDetail" activeClassName="current" className="dropdown-item" onClick={closeMobileMenu}>PU Painting</Link>
               <Link to="/AcrylicDetail" activeClassName="current" className="dropdown-item" onClick={closeMobileMenu}>Acrylic Painting</Link>
               <Link to="/TsaDetail" activeClassName="current" className="dropdown-item" onClick={closeMobileMenu}>TSA Painting</Link>
                 </div> */}
           </li>
            <li className='nav-item'>
              <NavLink to="/About" exact activeClassName="current" className='nav-links' onClick={closeMobileMenu}
              > About Us</NavLink>
            </li>
            <li className='nav-item'>
              <NavLink to="/Contact" exact activeClassName="current" className='nav-links' onClick={closeMobileMenu}
              >Contact Us</NavLink>
            </li>
            <li className='nav-item'>
              <NavLink to="/Careers" exact activeClassName="current" className='nav-links' onClick={closeMobileMenu}
              > Careers</NavLink>
            </li>

            {/* <li>
              <NavLink to='/sign-up' activeClassName="current" className='nav-links-mobile' onClick={closeMobileMenu}
              > Sign Up </NavLink>
            </li> */}
          </ul>
         
        </div>
      </nav>
    </>
  )
}

export default Header;
