import React from 'react';
import {Link} from "react-router-dom";
// import Video from '../images/banner-preview.mp4';
import Banner1 from '../images/homebanner-1.jpg';
import Banner2 from '../images/homebanner-2.jpg';
import Banner3 from '../images/homebanner-3.jpg';
import Carousel from "react-multi-carousel";
import '../css/slider-effect.css';
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

function Banner(){
    
        return (
          <section className="banner">
         <Carousel responsive={responsive} infinite autoPlay> 
          {/* <div className="embed-responsive embed-responsive-16by9">
          <video id="myVideo" loop autoPlay muted>
          <source src={Video} type="video/mp4" />
          <source src={Video} type="video/ogg" />
      </video>
      <div className="content">
        <h1 className="text-black">Customized Painting Solutions</h1>
          <Link to="/Services" className="btn read-more-btn">Read More</Link>
      </div>
      </div> */}
      <div>
      <img src={Banner1} className="img-fluid" alt="pupainting" />
      <div className="content">
        <h1 className="text-white">PU Painting Solutions</h1>
          <Link to="/PuPaintingDetail" className="btn read-more-btn">Read More</Link>
      </div>
      </div>
      <div>
      <img src={Banner2} className="img-fluid" alt="Tsa-painting" />
      <div className="content">
        <h1 className="text-white">TSA Painting Solutions</h1>
          <Link to="/TsaDetail" className="btn read-more-btn">Read More</Link>
      </div>
      </div>
      <div>
      <img src={Banner3} className="img-fluid" alt="Tsa-painting" />
      <div className="content">
        <h1 className="text-white">Acrylic Painting Solutions</h1>
          <Link to="/AcrylicDetail" className="btn read-more-btn">Read More</Link>
      </div>
      </div>
      </Carousel>
      </section>
      )
};
export default Banner;


