import React from 'react';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";


const responsive={
  desktop: {
    breakpoint: {
      max: 3000,
      min: 1024
    },
    items: 5,
    partialVisibilityGutter: 40
  },
  mobile: {
    breakpoint: {
      max: 464,
      min: 0
    },
    items: 1,
    partialVisibilityGutter: 30
  },
  tablet: {
    breakpoint: {
      max: 1024,
      min: 464
    },
    items: 2,
    partialVisibilityGutter: 30
  }
};
function OurPartners(props) {
  const [Client] = React.useState(props.itemsArray)

  return (
    <>
      <section className="section-content">
      <div className="container">
      <h2 className="heading-title title">Our Esteemed <span className="text-skyblue">Clients</span></h2>
      <center><div className="underline"></div></center><br/>
      {/* centerMode={true} */}
      <Carousel containerclassName="carousel-container-with-scrollbar"  itemclassName="image-item" partialVisible={false} itemAriaLabel="Image-aria-label" responsive={responsive} infinite autoPlay>
        {
        Client.map((item, index)=>{
          return(
            <div key={index} className="brands_item d-flex flex-column justify-content-center">
              <a href="!#"><img src={item} alt="/" className="img-fluid" /></a> 
            </div>
         )
        })
        }
      
    {/* <div className="brands_item d-flex flex-column justify-content-center">
      <a href="javascript:void()"><img src={Client2} className="img-fluid" /></a>
      </div>
      <div className="brands_item d-flex flex-column justify-content-center">
      <a href="javascript:void()"><img src={Client3} className="img-fluid" /></a>
       </div>
       <div className="brands_item d-flex flex-column justify-content-center">
      <a href="javascript:void()"><img src={Client4} className="img-fluid" /></a>
      </div>
      <div className="brands_item d-flex flex-column justify-content-center">
      <a href="javascript:void()"><img src={Client5} className="img-fluid" /></a>
       </div>
       <div className="brands_item d-flex flex-column justify-content-center">
      <a href="javascript:void()"><img src={Client6} className="img-fluid" /></a>
       </div>
       <div className="brands_item d-flex flex-column justify-content-center">
      <a href="javascript:void()"><img src={Client7} className="img-fluid" /></a>
       </div> */}
     
            </Carousel>
      </div>
     
      </section>
    </>
  )
}

export default OurPartners;
