import React, {useEffect, useState} from 'react';
import ContactBanner from '../images/contactus.jpg';
import Logo from '../images/final-logo.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { faPhone } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import {Link} from "react-router-dom";
import { motion } from 'framer-motion';
import axios from 'axios';
import ThankYou from './ThankYou';
import Swal from 'sweetalert2'
import MetaTag from './MetaTag';
var contactmetametatag = {
  title: "Contact | Ecoline Enterprises",
  des: "Ecoline Enterprises is a high quality, ISO 9001:2000 accredited, paint services provider based in Industrial hub of Ghaziabad.",
}

const fadeRight = {
  hidden: { opacity: 0, x: 100 },
  visible: { opacity: 1, x: 0, transition: { duration: 1.5 }}
};
const fadeLeft = {
  hidden: { opacity: 0, x: -100 },
  visible: { opacity: 1, x: 0, transition: { duration: 1.5 }}
};

function Contact() {

  const [Name, setName] = useState('');
  const [CompanyName, setCompanyName] = useState('');
  const [Email, setEmail] = useState('');
  const [Phone, setPhone] = useState('');
  const [Message, setMessage] = useState('');
 
 
    function Callapi2(e) {
      e.preventDefault();

    const mdata = new FormData();
    mdata.append( "Name",Name);
    mdata.append( "CompanyName", CompanyName);
    mdata.append( "Email", Email);
    mdata.append( "Phone", Phone);
    mdata.append( "Message", Message);
 
    axios.post('http://ecoline-enterprises.com/Api/updatecontactus.php',mdata)
    .then(function (response) {
    var mdata2 = response.data;
    setName('');
    setCompanyName('');
    setEmail('');
    setPhone('');
    setMessage('');

    console.log(JSON.stringify(mdata2));
    if(mdata2!=null){
      if(mdata2.Response =="1"){
         Swal.fire(
            'Success',
            'From submit successfully',
            'success'
          )
   
      }else{
   
   
         Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text:'Please  fill all required field first',
      
          })
      }
   }else{
      Swal.fire({
         icon: 'error',
         title: 'Oops...',
         text: 'Something went wrong! Retry again',
     
       })
   }
    })
    .catch(function (error) {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Something went wrong! Retry again',
    
      })
    });
    
    }
  
    
  return (
    <>
     <MetaTag title={contactmetametatag.title} des={contactmetametatag.des} />
    <img src={ContactBanner} className="img-fluid" alt="contact-banner"></img>
    <section className="section-content bg-1">
    <div className="container">

    <h2 className="heading-title title">Contact <span className="text-skyblue">Us</span></h2>
      <center><div className="underline"></div></center>
      
      <div className="row mt-5">
      <motion.div className="col-md-7" variants={fadeLeft} initial='hidden' animate='visible'>
      <div className="pattern-layer bg-3"></div>
      <form onSubmit={Callapi2}>
      {/* <form action="updatecontact.php" method="post"> */}
          <div className="row">
            <div className="col-md-6">
          <div className="form-group">
            <label>Name</label>
            <input type="text" className="form-control" 
            name="Name" value={Name} onChange={event => setName(event.target.value)} required />
          </div>
          </div>
          <div className="col-md-6">
          <div className="form-group">
            <label>Company</label>
            <input type="text" className="form-control" name="CompanyName" value={CompanyName} onChange={event => setCompanyName(event.target.value)} />
          </div>
          </div>
          </div>
          <div className="row">
            <div className="col-md-6">
          <div className="form-group">
            <label>Email</label>
            <input type="email" value={Email} className="form-control" name="Email" onChange={event => setEmail(event.target.value)} required />
          </div>
          </div>
          <div className="col-md-6">
          <div className="form-group">
            <label>Phone</label>
            <input type="text" className="form-control"  
             value= {Phone}
            name="Phone" onChange={event => setPhone(event.target.value)} />
          </div>
          </div>
          </div>
          <div className="col-md-12 pl-0 pr-0">
            <label>Message</label>
            <textarea value={Message} className="form-control" name="Message" onChange={event => setMessage(event.target.value)}></textarea>
          </div>
          <br />
          <center className="p-relative z-index">
           
            <button className="default-btn" type="submit" name="submit">Submit</button>
            {/* </Link> */}
            </center>
          </form>
    </motion.div>
    <motion.div className="col-md-5" variants={fadeRight} initial='hidden' animate='visible'>
   
    <div className="contact-box-inner mobile-mt-2">
      <img src={Logo} className="img-fluid mobile-width-60" alt="logo" />
      <br /><br />
      <table>
        <tbody>
        <tr>
          <td ><FontAwesomeIcon className="icon-color" icon={faHome} /></td>
          <td><p>&nbsp;&nbsp;&nbsp;</p></td>
          <td><span>C- 18/ 6- 7, B. S. R. Industrial Area,<br></br>Ghaziabad - 201 009, Uttar Pradesh, India </span></td>
        </tr>
        <tr>
          <td><FontAwesomeIcon className="icon-color" icon={faPhone} /></td>
          <td><p>&nbsp;&nbsp;&nbsp;</p></td>
          <td><a href="tel:+91 981 046 7500"><span>+91 981 046 7500</span></a></td>
        </tr>
        <tr>
          <td><FontAwesomeIcon className="icon-color" icon={faEnvelope} /></td>
          <td><p>&nbsp;&nbsp;&nbsp;</p></td>
          <td><a href="mailto:info@ecoline-enterprises.com"><span>info@ecoline-enterprises.com</span></a></td>
        </tr>
        </tbody>
      </table>
     </div>
     </motion.div>
    
    </div>
    
  </div>
  </section>
  
    </>
  )
}

export default Contact;
