import React from 'react';
import ServicesBanner from '../images/about-banner.jpg';
// import SmallBanner from '../images/mirror1.jpg';

function ServiceDetailsNew() {
  return(
    <>
    <img src={ServicesBanner} className="img-fluid" alt="service-banner" />
    <section className="section-content bg-gradient">
      <div className="container">
      <h2 className="heading-title title">PU Painting</h2>
      <center><div className="underline"></div></center>
      <div className="row mt-5">
        <div className="col-md-8">
          {/* <img src={SmallBanner} className="img-fluid" alt="pu-painting" /> */}
          <div className="product-inner">
          <div className="post_info">
				<span className="post_info_item">Started <a href="!#" className="post_info_date">Febuary 1, 2021</a></span>
				
		</div>
            <p className="text-justify">We offer a superior range of acrylic coating services and PU painting services to our wide spread clientele. Having a superb acrylic painting technology, we are able to provide acrylic / polyurethane painting that is chemical- resistant coating lending a smooth finish to a variety of industrial and commercial substrates.</p>
            <p className="text-justify">Additional features of acrylic painting and PU coating services include exceptional resistance to acids, alkalies, humidity and salts commonly found in industrial applications. Paint coating is as per specific requirement viz, Glossy, Semi Glossy, Silky, MATT.</p>

          </div>
        </div>
        <div className="col-md-4"></div>

      </div>
    </div>
    </section>
    </>
  )
}
export default ServiceDetailsNew;