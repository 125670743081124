import React from 'react';
import ServicesBanner from '../images/tsa-painting-banner.jpg';
import { TsaSubcategories } from '../components/SData';
import SubServices from '../components/SubServices';
import { motion } from 'framer-motion';
import AboutImg from '../images/tsa-small-banner.jpg';
import MetaTag from './MetaTag';
var tsametatag = {
  title: "TSA Painting Services | Ecoline Enterprises",
  des: "TSA thermo setting acrylic are used for metal surfaces, to give them desired colour and finish. TSA also provides these surfaces protection from corrosion. ",
}
// const fadeRight = {
//   hidden: { opacity: 0, x: 100 },
//   visible: { opacity: 1, x: 0, transition: { duration: 1.5 }}
// };
const fadeLeft = {
  hidden: { opacity: 0, x: -100 },
  visible: { opacity: 1, x: 0, transition: { duration: 1.5 }}
};


function TsaDetail(params) {
  return(
    <>
   <MetaTag title={tsametatag.title} des={tsametatag.des} />
<img src={ServicesBanner} className="img-fluid" alt="Tsa-paintig"></img>
      <section className="section-content">
      <div className="container">
      <h2 className="heading-title title z-index p-relative">TSA Painting</h2>
      <center><div className="underline z-index p-relative"></div></center>
      <div className="row mt-5">
     <div className="col-md-12">
     <div className="service-box">
      
      <div className="row">
      <div className="col-md-6">
      <div className="pattern-layer bg-3"></div>
      <div className="pattern-layer-two bg-4"></div>
      <div className="height10 hidden-xs"></div>
        <div className="z-index p-relative">
        <p>TSA thermo setting acrylic are used for metal surfaces, to give them desired colour and finish. TSA also provides these surfaces protection from corrosion. These acrylic coatings are done, after Pre-treatment, as per the component requirements. This offers high level scratch resistance to the components.</p>
        <div className="height2 hidden-xs"></div>
        <div className="height10 hidden-xs"></div>
        </div>
       </div>
     
       <div className="col-md-5">
       <div className="border-bg full-bg"></div>
      <div className="img-hover">
      <motion.div variants={fadeLeft} initial='hidden' animate='visible'>
      <img src={AboutImg} className="img-fluid img-hover" alt="Tsa-painting" />
      </motion.div>
      </div>
      </div>
      </div>
      </div>
      </div>
      </div>
      </div>
      </section>
      <section className="section-content bg-1">
      <div className="container">
      <h2 className="heading-title title">Applications</h2>
      <center><div className="underline"></div></center>
      <div className="icons1">
      <div className="icon-one-service"></div>
      <div className="icon-two-service"></div>
      <div className="icon-three-service bg-icon-3"></div>
      <div className="icon-four-service bg-icon-4"></div>
      <div className="icon-five-service bg-icon-5"></div>
      <div className="icon-six-service bg-icon-6"></div>
      </div>
     <br /><br />
      {
  TsaSubcategories.map((val, ind) => {
    return <SubServices key={ind} imgsrc={val.imgsrc} title={val.title} />
  })
}
      </div>
      </section>

    </>
  )
}
export default TsaDetail;