import React from 'react';
import {Link} from "react-router-dom";

function ServicesBox(props) {
return (
    <>
<div className="col-md-4 mt-5">
<br /><br />
  <div className="main">
    <div className="service">
      <div className="service-logo">
        <img src={props.imgsrc} className="img-fluid" alt={props.title} />
      </div>
      <h4>{props.title}</h4>
      <p>{props.des}</p>
      <Link to={props.link} className="default-btn">Read More</Link>
    </div>
  </div>
</div>

    </>
  )
}

export default ServicesBox;
