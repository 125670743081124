import React from 'react';
import "react-multi-carousel/lib/styles.css";
import Facilities from '../images/facilites.png';
import Workforce from '../images/workforce.png';
import Assurance from '../images/assurance.png';

function Features() {
  return (
    <>
    
      {/* <h2 className="heading-title title">Testimonials</h2>
      <center><div className="underline"></div></center> */}
      <br/> 
    <div className="row mt-5">
      <div className="col-md-4 text-center">
      <div className="customer"><img src={Facilities} className="img-fluid feature-img" alt="facilities" />
        <h4 className="sub-title text-center mt-3">Our Facilities</h4>
        <p className="text-center">We are well equipped with Pressurized Dust free paint booths, conveyorised & batch type baking ovens, sanders, polishers.</p>
        </div>
      </div>
      <div className="col-md-4 text-center">
        <div className="customer customer-yellow">
      <img src={Workforce} className="img-fluid feature-img" alt="workforce" />
      <h4 className="sub-title-yellow text-center mt-3">Our Workforce</h4>
      <p className="text-center">Our competent team comprising of engineers, technicians and skilled laborers work in harmony to offer high quality painting solutions to clients.</p>
      </div>
      </div>
      <div className="col-md-4 text-center">
      <div className="customer customer-red"><img src={Assurance} className="img-fluid feature-img" alt="assurab=nce" />
      <h4 className="sub-title-red text-center mt-3">Quality Assurance</h4>
     <p className="text-center">Being an ISO 9001:2000 certified company, we lay special emphasis in maintaining excellent quality in all our painting services.</p>
     </div>
      </div>
    </div>
     
    </>
  )
}

export default Features;
