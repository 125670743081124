import React from 'react';
import AboutBanner from '../images/about-banner.jpg';
import Features from './Features';
import MetaTag from './MetaTag';
var aboutmetatag = {
  title: "About | Ecoline Enterprises",
    des: "Established in 1999, Ecoline Enterprises is a high quality, ISO 9001:2000 accredited, paint services provider based in Industrial hub of Ghaziabad.",
}

function About() {
  return (
    <>
    <MetaTag title={aboutmetatag.title} des={aboutmetatag.des} />
    <img src={AboutBanner} className="img-fluid" alt="banner-img"></img>
    <section className="section-content bg-1">
    <div className="container">

    <h2 className="heading-title title">About <span className="text-skyblue">Us</span></h2>
      <center><div className="underline"></div></center>
      <div className="row mt-5">
        <div className="col-md-8 offset-md-2">
      <p className="text-center">Established in 1999, Ecoline Enterprises is a high quality, ISO 9001:2000 accredited, paint services provider based in Industrial hub of Ghaziabad.</p>
<p className="text-center">We specialise in acrylic and Polyurethane (PU) painting on:</p>
 <center><ul className="about-list">
  <li>Plastic</li>
  <li>Wood</li>
  <li>Glass</li>
  <li>Ferrous and Non-Ferrous metals </li>
</ul></center>

<p className="text-center">Our mechanised as well as the manual application technology and post-application analysis conforms to the desired quality standards.</p>

    </div>
    </div>
  </div>
  </section>
  <section className="bg-2">
 
    <div className="container">
    <h2 className="heading-title title">Why <span className="text-skyblue">Ecoline ?</span></h2>
      <center><div className="underline"></div></center>
    <div className="icons1">
      <div className="icon-one-service"></div>
      <div className="icon-two-service"></div>
      <div className="icon-three-service bg-icon-3"></div>
      <div className="icon-four-service bg-icon-4"></div>
      <div className="icon-five-service bg-icon-5"></div>
      <div className="icon-six-service bg-icon-6"></div>
      </div>
      <div className="icons">
      <div className="icon-one"></div>
     <div className="icon-two"></div>
     <div className="icon-three"></div>
     <div className="icon-four"></div>
     <div className="icon-five bg-icon-1"></div>
     <div className="icon-six bg-icon-2"></div>
     <div className="icon-five1 bg-icon-1"></div>
     <div className="icon-six1 bg-icon-2"></div>
     </div>
    <div className="row pt-5 pb-5">
      <div className="col-md-4">
      <div className="features-list">
      <i className="fas fa-certificate"></i>
     
      <div className="ornament"></div><br />
      <h4 className="sub-title text-center">Conforming to Quality standards</h4>
      </div>
      </div>
      <div className="col-md-4">
      <div className="features-list features-list-yellow">
      <i className="fab fa-product-hunt"> </i>
      
      <div className="ornament"></div><br />
      <h4 className="sub-title-yellow text-center">Cutomised Painting solutions</h4>
      </div>
      </div>
      <div className="col-md-4">
      <div className="features-list features-list-red">
      <i className="fas fa-smile"></i>
      <div className="ornament"></div><br />
      <h4 className="sub-title-red text-center sub-title-green">Customer satisfaction </h4>
      </div>
      </div>
    </div>
    </div>
  </section>
  <section className="bg-6">
  <div className="container pt-3 pb-4">
{/* <h2 className="heading-title title">Our <span className="text-skyblue">Services</span></h2>
  <center><div className="underline"></div></center> */}
 <Features />
 <br />
 <br />
  </div>
  </section>
    </>
  )
}

export default About;
