import React from 'react';
import {Link} from "react-router-dom";
// import styled from 'styled-components';
import { motion } from 'framer-motion';
import AboutImg from '../images/homepage.jpg';

const fadeRight = {
  hidden: { opacity: 0, x: 100 },
  visible: { opacity: 1, x: 0, transition: { duration: 1.5 }}
};
const fadeLeft = {
  hidden: { opacity: 0, x: -100 },
  visible: { opacity: 1, x: 0, transition: { duration: 1.5 }}
};

function AboutSection() {
  return (
    <>
      <section className="section-content about-bg">
      <div className="container">
      <h2 className="heading-title title">About <span className="text-skyblue">Us</span></h2>
      <center><div className="underline"></div></center>
      <div className="row pt-5">
      <div className="col-md-6">
      <div className="img-hover">
      <motion.div variants={fadeLeft} initial='hidden' animate='visible'>
      <img src={AboutImg} className="img-fluid img-hover" alt="about-img" />
      </motion.div>
      </div>
      {/* <div className="car-icon car-bf"></div> */}
      </div>
      <div className="col-md-6">
      <motion.p variants={fadeRight} initial='hidden' animate='visible'>
    
        Established in 1999, Ecoline Enterprises is a high quality, ISO 9001:2000 accredited, paint services provider based in Industrial hub of Ghaziabad.<br />
     We provide the best-suited painting, application technology, and post-application analysis to conform to quality standards. We also offer customized painting solutions to meet the varied requirements of automobile, appliances and other industries.
      </motion.p>
      <br />
      <Link to="/About"> <button className="default-btn">Read More</button></Link>
    
      </div>
      </div>
      </div>
      </section>
    </>
  )
}

export default AboutSection;
